import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';
import { Icon } from '@components/icons';
// import 'boxicons';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      background: transparent;
      outline: 0;

      &:after {
        top: 15px;
        left: 15px;
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 20px;
      left: 20px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = ['TypeScript', 'Express', 'Eleventy', 'EJS', 'React Native', 'MySQL'];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              I'm a MERN Stack Developer, but you can call me a mathematician. Math has always been
              a part of my life; in school, in Physics, and now in code. I love exploring, and that
              led to me discovering the field of development. Early on, I realised I was very
              interested in web development.
            </p>

            <p>
              I worked with{' '}
              <a href="https://devfolio.co" target="_blank" rel="noreferrer">
                Devfolio
              </a>{' '}
              for 1.5 years as a Community and Operations Associate and operated two major
              hackathons–
              <a href="https://hackinout.co" target="_blank" rel="noreferrer">
                Hack InOut
              </a>{' '}
              and{' '}
              <a href="https://ethindia.co" target="_blank" rel="noreferrer">
                ETHIndia
              </a>
              . I think this is when I decided to seriously pursue a career in web development, and
              that's how I landed at{' '}
              <a href="https://masaischool.com" target="_blank" rel="noreferrer">
                Masai School
              </a>
              . Currently, I'm skilled in both frontend and backend technology, especially MERN
              Stack. At Masai School, I have built clones of major websites like PharmEasy, Myntra,
              and OYORooms. I also worked here as Instructional Associate and worked on great
              products.
            </p>

            <p>Here are a few technologies I’ve been working with recently:</p>
          </div>

          <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/me.jpg"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
          <div style={{ marginTop: '80px' }}>
            <div
              style={{
                display: 'flex',
                margin: 'auto',
                justifyContent: 'space-evenly',
              }}>
              <div style={{ margin: '10px' }}>
                <div style={{ height: '40px', width: '40px', margin: 'auto' }}>
                  <Icon name="JavaScript" />
                </div>
                <p>JavaScript</p>
              </div>

              <div style={{ margin: '10px' }}>
                <div style={{ height: '40px', width: '40px', margin: 'auto' }}>
                  <Icon name="React" />
                  <p style={{ textAlign: 'center' }}>React</p>
                </div>
              </div>
              <div style={{ margin: '10px' }}>
                <div style={{ height: '40px', width: '40px', margin: 'auto' }}>
                  <Icon name="Redux" />
                </div>
                <p style={{ textAlign: 'center' }}>Redux</p>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                margin: 'auto',
                justifyContent: 'space-evenly',
              }}>
              <div style={{ margin: '10px' }}>
                <div style={{ height: '40px', width: '40px', margin: 'auto' }}>
                  <Icon name="Mongo" />
                </div>
                <p style={{ textAlign: 'center' }}>MongoDB</p>
              </div>

              <div style={{ margin: '10px' }}>
                <div style={{ height: '40px', width: '40px', margin: 'auto' }}>
                  <Icon name="Node" />
                  <p style={{ textAlign: 'center' }}>Nodejs</p>
                </div>
              </div>
              <div style={{ margin: '10px' }}>
                <div style={{ height: '40px', width: '40px', margin: 'auto' }}>
                  <Icon name="CSS" />
                  <p style={{ textAlign: 'center' }}>CSS</p>
                </div>
              </div>
            </div>
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
