import React from 'react';

const IconLogo = () => (
  <svg
    width="500.000000pt"
    height="500.000000pt"
    viewBox="0 0 500.000000 500.000000"
    xmlns="http://www.w3.org/2000/svg">
    <g
      transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
      fill="#0a192f"
      stroke="none">
      <path
        d="M0 2500 l0 -2500 2500 0 2500 0 0 2500 0 2500 -2500 0 -2500 0 0
-2500z m2796 603 c-3 -362 -9 -700 -12 -750 l-5 -93 13 0 c7 1 44 47 81 104
37 57 173 236 301 397 l232 294 353 3 353 2 -39 -47 c-134 -161 -585 -717
-594 -731 l-11 -18 314 -489 c173 -270 327 -509 342 -533 l26 -42 -361 2 -362
3 -199 325 -199 325 -17 -1 c-10 0 -61 -34 -115 -75 l-97 -74 0 -252 0 -253
-320 0 -320 0 0 115 0 115 -14 0 c-8 0 -31 -22 -51 -49 -93 -128 -234 -203
-410 -217 l-80 -6 -60 11 c-288 57 -471 286 -541 676 l-18 100 0 185 0 185 18
99 c63 346 218 562 466 648 l55 19 90 6 91 6 68 -12 c129 -22 233 -85 319
-191 22 -28 47 -50 55 -50 l15 0 -7 103 c-3 56 -9 263 -12 460 l-7 357 332 0
333 0 -6 -657z"
      />
      <path
        d="M1803 2559 c-106 -52 -154 -149 -173 -346 l-9 -98 10 -104 10 -104
26 -66 c42 -107 107 -162 208 -175 l50 -7 55 11 55 10 40 28 c22 15 50 46 62
67 l23 40 0 298 0 298 -20 38 c-40 80 -136 131 -244 131 l-51 0 -42 -21z"
      />
    </g>
    <g
      transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
      fill="#6ef7d9"
      stroke="none">
      <path
        d="M2134 3398 c3 -205 9 -413 12 -463 l5 -90 -38 45 c-68 80 -117 120
-188 155 l-70 34 -70 12 -69 12 -101 -6 -100 -6 -55 -19 c-248 -86 -403 -302
-466 -648 l-18 -99 0 -195 0 -195 18 -100 c70 -391 253 -619 541 -676 l60 -12
90 7 c188 13 325 87 438 236 l26 35 1 -117 0 -118 330 0 330 0 0 253 0 252 95
72 c52 40 99 74 104 75 5 2 98 -141 206 -317 107 -176 199 -323 203 -327 5 -5
176 -7 380 -6 l373 3 -222 345 c-122 190 -277 431 -344 536 l-123 192 319 390
c176 215 319 396 319 401 l0 11 -362 -2 -362 -3 -232 -293 c-127 -161 -264
-341 -304 -400 l-71 -107 5 85 c4 47 9 385 12 753 l6 667 -343 0 -342 0 7
-372z m-148 -843 c60 -18 118 -64 144 -116 l20 -38 0 -288 0 -288 -23 -40
c-12 -21 -40 -52 -62 -67 l-40 -28 -54 -10 c-137 -26 -241 35 -294 171 l-26
66 -10 99 -9 99 9 93 c18 191 67 289 172 341 l42 21 41 0 c22 0 63 -7 90 -15z"
      />
    </g>
    <g
      transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
      fill="#4cac9f"
      stroke="none">
      <path
        d="M2798 3438 c1 -183 3 -416 5 -518 1 -102 3 48 5 333 1 284 -1 517 -5
517 -5 0 -7 -150 -5 -332z"
      />
      <path
        d="M2805 1697 c-3 -7 -4 -125 -3 -262 l3 -250 5 263 c5 256 4 275 -5
249z"
      />
    </g>
    <g
      transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
      fill="#61dbc3"
      stroke="none">
      <path
        d="M1101 2751 c-17 -34 -29 -61 -27 -61 7 0 66 113 62 118 -3 2 -19 -23
-35 -57z"
      />
      <path d="M2780 2297 c0 -26 4 -47 9 -47 l9 0 -5 43 c-8 58 -13 60 -13 4z" />
      <path
        d="M3730 1865 c0 -10 53 -85 57 -81 3 2 -8 23 -23 45 -25 39 -34 48 -34
36z"
      />
      <path
        d="M1720 1753 c0 -14 68 -68 76 -61 2 3 -8 13 -23 23 -15 10 -33 26 -40
34 l-12 16 -1 -12z"
      />
      <path
        d="M3820 1725 c0 -10 53 -85 57 -81 3 2 -8 23 -23 45 -25 39 -34 48 -34
36z"
      />
      <path
        d="M2045 1320 c-16 -17 -24 -30 -19 -30 13 0 59 48 52 55 -2 3 -17 -9
-33 -25z"
      />
    </g>
    <g
      transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
      fill="#2d676b"
      stroke="none">
      <path
        d="M2154 2115 c0 -154 2 -216 3 -137 2 79 2 205 0 280 -1 75 -3 11 -3
-143z"
      />
      <path
        d="M2148 1348 c5 -150 8 -165 10 -40 1 67 -1 122 -5 122 -5 0 -7 -37 -5
-82z"
      />
    </g>
    <g
      transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
      fill="#3b8783"
      stroke="none">
      <path
        d="M3401 3065 c7 -6 720 -7 727 0 3 3 -160 5 -363 5 -203 0 -366 -2
-364 -5z"
      />
    </g>
    <g
      transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
      fill="#295f64"
      stroke="none">
      <path
        d="M2134 3510 c0 -146 2 -205 3 -132 2 72 2 192 0 265 -1 72 -3 13 -3
-133z"
      />
    </g>
    <g
      transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
      fill="#55bfae"
      stroke="none">
      <path
        d="M2308 3763 c89 -2 235 -2 325 0 89 1 16 3 -163 3 -179 0 -252 -2
-162 -3z"
      />
    </g>
    <g
      transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
      fill="#0e2337"
      stroke="none">
      <path
        d="M1225 2930 c-16 -16 -26 -32 -24 -35 3 -2 21 11 39 30 19 19 29 35
24 35 -6 0 -23 -13 -39 -30z"
      />
    </g>
    <g
      transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
      fill="#163444"
      stroke="none"
    />
    <g
      transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
      fill="#0c1d32"
      stroke="none"
    />
    <g
      transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
      fill="#347777"
      stroke="none"
    />
    <g
      transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
      fill="#112a3c"
      stroke="none"
    />
    <g
      transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
      fill="#5acab6"
      stroke="none"
    />
    <g
      transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
      fill="#23525a"
      stroke="none"
    />
    <g
      transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
      fill="#48a399"
      stroke="none"
    />
    <g
      transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
      fill="#1c424e"
      stroke="none"
    />
    <g
      transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
      fill="#39827f"
      stroke="none"
    />
    <g
      transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
      fill="#204a55"
      stroke="none"
    />
    <g
      transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
      fill="#51b5a7"
      stroke="none"
    />
    <g
      transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
      fill="#193c49"
      stroke="none"
    />
    <g
      transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
      fill="#3f8e89"
      stroke="none"
    />
    <g
      transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
      fill="#439991"
      stroke="none"
    />
    <g
      transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
      fill="#56c2b1"
      stroke="none"
    />
    <g
      transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
      fill="#53bbab"
      stroke="none"
    />
  </svg>
);

export default IconLogo;
